import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'
import { useSetVisibility } from '@/lib/hooks/useSetVisibility'
import { FragmentComponent } from '@/types/graphql'
import { Image } from 'react-datocms'
import { styled } from 'styled-components'
import { ImageBentoGridRecord } from 'types'

const fragment = `
  fragment ImageBentoGridFragment on ImageBentoGridRecord {
    numberOfColumns
    numberOfRows
    visibility
    maxWidth
    content {
      __typename
      columnSpan
      rowSpan
      image {
        ${responsiveImageBlock()}
      }
    }
  }
`

export const StyledBentoGrid = styled.div<{ $gridCols: number; $gridRows: number; $maxWidth: number }>`
  display: grid;
  gap: var(--grid-16);
  ${({ $gridCols }) => `grid-template-columns: repeat(${$gridCols}, 1fr)`};
  ${({ $gridRows }) => `grid-template-rows: repeat(${$gridRows}, 1fr)`};
  ${({ $maxWidth }) => `max-width: ${$maxWidth}px`};

  margin: 0 auto;

  @media screen and (max-width: ${BREAKPOINTS.s}) {
    gap: var(--grid-8);
    max-width: 100%;
  }
`

const StyledBentoCard = styled.div<{ $col: number; $row: number }>`
  ${({ $col }) => `grid-column: span ${$col}`};
  ${({ $row }) => `grid-row: span ${$row}`};
  border-radius: var(--radius-16);
  overflow: hidden;
  position: relative;
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    border-radius: 0;
  }
`

const StyledImage = styled(Image)`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const ImageBentoGrid: FragmentComponent<{}, ImageBentoGridRecord> = ({ record }) => {
  const isVisible = useSetVisibility(record?.visibility ?? [])

  return isVisible ? (
    <StyledBentoGrid
      $gridCols={record.numberOfColumns}
      $gridRows={record.numberOfRows}
      $maxWidth={record.maxWidth || 1800}
    >
      {record.content.map(({ image, columnSpan, rowSpan }, index) => (
        <StyledBentoCard key={index} $col={columnSpan} $row={rowSpan}>
          <StyledImage data={image.responsiveImage} />
        </StyledBentoCard>
      ))}
    </StyledBentoGrid>
  ) : null
}

ImageBentoGrid.fragment = fragment
ImageBentoGrid.recordName = 'ImageBentoGridRecord'
